
// eslint-disable-next-line max-len
import { Container, Content, FlexboxGrid, Panel, Form, FormGroup, ControlLabel, FormControl, ButtonToolbar, Footer, Button } from 'rsuite'

// eslint-disable-next-line react/prop-types
const Register = ({ onSignUp, email, password, setEmail, setPassword, header }) => (
  <>
    <div className='show-fake-browser login-page'>
      <Container>
        <Content>
          <FlexboxGrid justify='center'>
            <FlexboxGrid.Item colspan={12}>
              <Panel header={<h3>{header}</h3>} bordered>
                <Form fluid>
                  <FormGroup>
                    <ControlLabel>E Mail</ControlLabel>
                    <FormControl
                      onChange={e => setEmail(e)}
                      value={email}
                      name='name'
                    />
                  </FormGroup>
                  <FormGroup>
                    <ControlLabel>şifre</ControlLabel>
                    <FormControl
                      onChange={e => setPassword(e)}
                      value={password}
                      name='password'
                      type='password'
                    />
                  </FormGroup>
                  <FormGroup>
                    <Button onClick={() => onSignUp()} appearance='primary'>{header}</Button>
                  </FormGroup>
                </Form>
              </Panel>
            </FlexboxGrid.Item>
          </FlexboxGrid>
        </Content>
      </Container>
    </div>
  </>
)
export default Register
